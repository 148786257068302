import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home-page" */ "../views/pages/Home.vue"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login-page" */ "../views/auth/Login.vue"),
  },

  {
    path: "/verify-login/:token",
    name: "verify-login",
    component: () => import(/* webpackChunkName: "verify-login-page" */ "../views/auth/VerifyLogin.vue"),
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "areas-dashboard-index" */ "../views/areas/dashboard/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "profile-page" */ "../views/areas/profile/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/users",
    name: "users",
    component: () => import(/* webpackChunkName: "users-index" */ "../views/areas/users/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/resources",
    name: "resources",
    component: () => import(/* webpackChunkName: "resources-index" */ "../views/areas/resources/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/resources/create",
    name: "resourcescreate",
    component: () => import(/* webpackChunkName: "resources-create" */ "../views/areas/resources/Create.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/resources/:id",
    name: "resourcesview",
    component: () => import(/* webpackChunkName: "resources-view" */ "../views/areas/resources/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/client-audits/create",
    name: "client-auditscreate",
    component: () => import(/* webpackChunkName: "client-audits-create" */ "../views/areas/client-audits/Create.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/client-audits/:id",
    name: "client-auditsview",
    component: () => import(/* webpackChunkName: "client-audits-view" */ "../views/areas/client-audits/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/faqs",
    name: "faqs",
    component: () => import(/* webpackChunkName: "faqs-index" */ "../views/areas/faqs/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/faqs/create",
    name: "faqscreate",
    component: () => import(/* webpackChunkName: "faqs-create" */ "../views/areas/faqs/Create.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/faqs/:id",
    name: "faqsview",
    component: () => import(/* webpackChunkName: "faqs-view" */ "../views/areas/faqs/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/links",
    name: "links",
    component: () => import(/* webpackChunkName: "links-index" */ "../views/areas/links/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/links/create",
    name: "linkscreate",
    component: () => import(/* webpackChunkName: "links-create" */ "../views/areas/links/Create.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/links/:id",
    name: "linksview",
    component: () => import(/* webpackChunkName: "links-view" */ "../views/areas/links/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/packages",
    name: "packages",
    component: () => import(/* webpackChunkName: "packages-index" */ "../views/areas/packages/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/packages/create",
    name: "packagescreate",
    component: () => import(/* webpackChunkName: "packages-create" */ "../views/areas/packages/Create.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/packages/:id",
    name: "packagesview",
    component: () => import(/* webpackChunkName: "packages-view" */ "../views/areas/packages/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/events",
    name: "events",
    component: () => import(/* webpackChunkName: "events-index" */ "../views/areas/events/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/events/create",
    name: "eventscreate",
    component: () => import(/* webpackChunkName: "events-create" */ "../views/areas/events/Create.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/events/:id",
    name: "eventsview",
    component: () => import(/* webpackChunkName: "events-view" */ "../views/areas/events/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/users/:id",
    name: "user-view",
    component: () => import(/* webpackChunkName: "users-view" */ "../views/areas/users/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/support-tickets/create",
    name: "support-tickets-create",
    component: () =>
      import(/* webpackChunkName: "support-tickets-create" */ "../views/areas/support-tickets/Create.vue"),
    meta: {
      auth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  const loggedInUser = store.user;

  if (to.matched.some((record) => record.meta.auth) && !loggedInUser) {
    next("/login");
    return;
  }

  if (to.path === "/login" || to.path === "/") {
    if (loggedInUser) {
      next("/dashboard");
    }
  }

  const menuToggle = document.getElementById("collapsableNav");
  const bsCollapse = new bootstrap.Collapse(menuToggle, {
    toggle: false,
  });
  bsCollapse.hide();

  next();
});

export default router;
