import axios from "axios";
import { useStore } from "@/store";

const api = axios;

// Setup default base url
api.defaults.baseURL = process.env.VUE_APP_API_URL;
api.defaults.withCredentials = false;
api.defaults.headers.common["Content-Type"] = "application/json";
api.defaults.headers.common["Accept"] = "application/json";

api.interceptors.request.use(
  (config) => {
    const store = useStore();
    const token = store.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("error", error);
    const store = useStore();
    if (error.response.status === 401) {
      store.signOut();
    }
    return Promise.reject(error);
  }
);

export { api };
