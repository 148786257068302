import { defineStore } from "pinia";
import { api } from "@/services/api";
import router from "@/router";
import { auth } from "@/services/firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";

export const useStore = defineStore({
  id: "main-store",
  persist: true,
  state: () => ({
    user: null,
    token: null,
  }),
  actions: {
    async requestEmailLink(email) {
      return await api.post("/request-email-link", {
        email,
        requester: "staff-portal",
      });
    },
    async verifyEmailLink(token) {
      const { data } = await api.post("/verify-email-link", { token: token });
      if (data.success) {
        this.user = data.user;
        router.push("/dashboard");
      } else {
        this.user = null;
        router.push("/login");
      }
    },

    async emailPasswordLogin(payload) {
      signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log("user", user);
          this.token = await user.getIdToken();
          await this.refreshUser();
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    },

    async checkRedirectResult() {
      try {
        const result = await getRedirectResult(auth);

        if (result) {
          // User is signed in.
          // IdP data available in result.additionalUserInfo.profile.

          // Get the OAuth access token and ID Token
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;

          this.token = await auth.currentUser.getIdToken();
          this.refreshUser();
          return true;
        } else {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async microsoftLogin() {
      const provider = new OAuthProvider("microsoft.com");
      signInWithRedirect(auth, provider);
    },

    async refreshUser() {
      if (this.token) {
        const { data } = await api.get("/me");
        if (data) {
          this.user = data;
          router.push("/dashboard");
        } else {
          this.signOut();
        }
      }
    },

    signOut() {
      signOut(auth)
        .then(() => {
          this.$patch({
            user: null,
            token: null,
          });

          router.push("/");
        })
        .catch((error) => {
          // An error happened.
          console.log("signout error", error);
          this.$patch({
            user: null,
          });

          router.push("/");
        });
    },
  },
});
