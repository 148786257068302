<template>
  <nav
    class="
      navbar navbar-expand-lg
      bg-gradient
      navbar-dark
      text-light
      shadow
      py-4
    "
  >
    <div class="container-fluid">
      <router-link
        class="
          navbar-brand
          d-flex
          justify-content-center
          align-items-center
          text-light
          fw-bold
        "
        to="/"
      >
        IBB Immigration Portal
      </router-link>
      <button
        class="navbar-toggler fs-1 text-white border-white"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsableNav"
        aria-controls="collapsableNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="far fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="collapsableNav">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-uppercase fw-semibold">
          <li class="nav-item me-md-4" v-if="!user">
            <router-link class="nav-link active" to="/">Home</router-link>
          </li>

          <li class="nav-item me-md-4" v-if="user">
            <router-link class="nav-link" to="/dashboard"
              >Dashboard</router-link
            >
          </li>

          <li class="nav-item me-md-4" v-if="user">
            <router-link class="nav-link" to="/users">Users</router-link>
          </li>

          <li class="nav-item me-md-4" v-if="user">
            <router-link class="nav-link" to="/packages">Packages</router-link>
          </li>

          <li class="nav-item me-md-4" v-if="user">
            <router-link class="nav-link" to="/links">Links</router-link>
          </li>

          <li class="nav-item me-md-4" v-if="user">
            <router-link class="nav-link" to="/faqs">FAQs</router-link>
          </li>

          <li class="nav-item me-md-4" v-if="user">
            <router-link class="nav-link" to="/events">Events</router-link>
          </li>

          <li class="nav-item me-md-4" v-if="user">
            <router-link class="nav-link" to="/profile">Profile</router-link>
          </li>

          <li class="nav-item dropdown" v-if="user">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ user.name }}
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <router-link class="dropdown-item" to="/profile"
                  >My Profile</router-link
                >
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <router-link class="dropdown-item" to="/support-tickets/create"
                  >Leave Feedback</router-link
                >
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <div v-if="user" @click="signOut" class="dropdown-item">
                  Sign out
                </div>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link v-if="!user" to="/login" class="nav-link"
              >Sign in</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useStore } from "@/store";
import { computed } from "vue";

export default {
  setup() {
    const store = useStore();
    const user = computed(() => store.user);
    return { user, signOut: () => store.signOut() };
  },
};
</script>

<style lang="scss" scoped>
</style>
